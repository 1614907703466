import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Header from './Header';
import SideBar from './SideBar';
import Module from './Module';
import { Column, Row } from 'simple-flexbox';
import Login from './modules/login/Login';
import ScrollToTop from './functional-components/ScrollToTop';

import { withTranslation } from 'react-i18next';

import "bootstrap/dist/css/bootstrap.min.css";
import '../assets/css/pacificCapital/main.scss';
import '../assets/css/t365/main.scss';
import '../assets/css/trustbucks/main.scss';
import '../assets/css/main.css';
import { whiteLabel } from '../config';

const logoutChannel = new BroadcastChannel('logout');

class App extends Component {
  state = {
    userLoggedIn: false,
    loggedAsUser: false
  };

  componentDidMount() {
    const queryParameters = new URLSearchParams(window.location.search);
    const user = JSON.parse(localStorage.getItem('user'));
    const loggedAsUserToken = JSON.parse(localStorage.getItem('loginAsUser'));
    const loggedAsUserEmail = localStorage.getItem('loginAsUserEmail');
    const loginAsURLToken = queryParameters.get("token");
    const loginAsUserEmail = queryParameters.get("email");

    if ((user && user.accessToken) || loginAsURLToken || loggedAsUserToken) {
      if (loginAsURLToken) {
        localStorage.setItem('loginAsUser', loginAsURLToken);
        localStorage.setItem('loginAsUserEmail', loginAsUserEmail);
      }
      this.setState({
        userLoggedIn: true,
        ...((loginAsURLToken || loggedAsUserToken) && { loggedAsUser: true }),
        ...((loginAsUserEmail || loggedAsUserEmail) && { loggedAsUserEmail: loginAsUserEmail || loggedAsUserEmail })
      });
    } else {
      localStorage.removeItem('loginAsUser');
      localStorage.removeItem('loginAsUserEmail');
    }
  };

  componentDidUpdate() {
    logoutChannel.onmessage = () => {
      this.logout();
      logoutChannel.close(); 
    };
  };

  handleCloseLoginAsMode = () => {
    this.logout();
  };

  logout = () => {
    logoutChannel.postMessage('Logout');
    localStorage.removeItem('loginAsUser');
    localStorage.removeItem('loginAsUserEmail');
    window.location.reload();
  };

  render() {
    const { loggedAsUser, loggedAsUserEmail, userLoggedIn } = this.state;

    return (
      <div>
        <BrowserRouter>
          <ScrollToTop>
            <Route>
              <Column className={ `${whiteLabel} content ${userLoggedIn ? 'logged-in' : 'non-logged-in'} ${loggedAsUser ? 'view-only' : ''}` }>
                {loggedAsUser && (
                  <Row flexGrow={ 1 } className="logged-as-user">
                    <h4 className="title"> You&apos;re now logged in as <span>{ loggedAsUserEmail }</span> </h4>
                    <button
                      className="close-btn"
                      onClick={ () => this.handleCloseLoginAsMode() }
                    >
                      End session
                    </button>
                  </Row>
                )}
                <Header userLoggedIn={ userLoggedIn || loggedAsUser } />
                <Row flexGrow={ 1 }>

                  { userLoggedIn &&
                    <Column className={ `${whiteLabel} sidebarColumn` }>
                      <SideBar />
                    </Column>
                  }

                  <Column className={ `${whiteLabel} contentColumn` } style={ { width: '100%' } }>
                    { userLoggedIn ? <Module /> : <Login /> }
                  </Column>
                </Row>
              </Column>
            </Route>
          </ScrollToTop>
        </BrowserRouter>
      </div>
    );
  }
}

export default withTranslation("common")(App);