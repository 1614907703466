import React, { Component } from 'react';
import Select from "react-select";

class CustomSelect extends Component {
  state = {};

  sortItems = (items) => {
    if (!items) return;
    
    items.sort((elemA, elemB) => !elemA.prevent_sorting && !elemB.prevent_sorting && elemA.label.localeCompare(elemB.label));

    return items;
  };

  render() {
    const { id, value, required, clearable, onChange, options, className, disabled, enableSort } = this.props;

    return (
      <Select
        name={ id }
        className={ className }
        value={ (options && options.length && options.find(option => option.value.toString() === value.toString())) || "" }
        isDisabled={ disabled }
        required={ required }
        isClearable={ clearable }
        isSearchable={ true }
        onChange={ (event) => onChange(event) }
        styles={ {
          control: (baseStyles, state) => ({
            ...baseStyles,
            minHeight: "44px",
            cursor: "pointer",
            ...(state.isDisabled && {
              backgroundColor: "#F9F9F9",
              borderColor: "#CCCCCC"
            })
          }),
          singleValue: (baseStyles, state) => ({
            ...baseStyles,
            ...(state.isDisabled && { color: "#555" })
          }),
          menu: (baseStyles, state) => ({
            ...baseStyles,
            margin: 0,
            zIndex: 4
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            cursor: "pointer"
          })
        } }
        options={ enableSort === false ? options :  this.sortItems(options) }
      />
    );
  }
}

export default CustomSelect;