import { whiteLabelId } from "../config.js";

export const loginActions = {
  login,
  totpLogin,
  logout
};

function login(username, password) {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'cache-control': 'no-cache', 'Company': whiteLabelId },
    body: JSON.stringify({ username, password })
  };

  return fetch('/login/auth/client', requestOptions)
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response.json();
    }).then(handleSuccess, handleError);

}

function totpLogin(secret, hash, code) {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'cache-control': 'no-cache', 'Company': whiteLabelId },
    body: JSON.stringify({ secret, hash, code })
  };

  return fetch('/login/auth/totp', requestOptions)
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response.json();
    }).then(handleSuccess, handleError);

}

function handleSuccess(user) {
  if (user && user.accessToken) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('user', JSON.stringify(user));
  }

  return user;
}

function handleError(err) {
  return err.json().then(json => {
    return Promise.reject(json);
  });
}

function logout() {
  localStorage.removeItem('user'); // remove user from local storage to log user out
}
