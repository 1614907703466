import React, {Component} from 'react';

import ComponentHeader from "./componentHeader";
// import {crudActions} from "../../../services/crudActions";
import { Row } from 'simple-flexbox';

import '../assets/css/apiData.css';

class EmptyScreen extends Component {
  state = {

  };

  componentDidMount() {
    this.props.history.push(`/dashboard`);
  };

  render() {
    return (
      <Row flexGrow={ 1 } className='module apidata' vertical='start'>
        <ComponentHeader title={ "" }/>
      </Row>
    );
  }
}

export default EmptyScreen;
