import React, {Component} from 'react';
import { Route } from 'react-router-dom';
import APIData from './modules/apiData/APIData';
import GenerateReport from './modules/generateReport/GenerateReport';
import EmptyScreen from './EmptyScreen';
import API from './modules/softwareIntegration/API';
import MyAccount from './modules/myAccount/MyAccount';

import Refunds from './modules/refunds/Refunds';
import RequestSettlement from './modules/finances/RequestSettlement';
import BankCodes from './modules/bankCodes/BankCodes';

import { crudActions } from './../services/crudActions';
import { Row } from 'simple-flexbox';
import { FETCH_PERMISSIONS } from '../actions/types';
const combineReducers =  require('../reducers/index');

class Module extends Component {
  state = {
    permissions: [],
    roleId: ""
  };

  componentDidMount() {
    crudActions.get(`v1/myaccount/me`).then(
      (user) => {
        if (user) {
          combineReducers.default.dispatch({ type: FETCH_PERMISSIONS, payload: user.permissions, roleId: user.roleId });
          this.setState({
            permissions: user.permissions,
            roleId: user.roleId
          });
        }
      }
    ).catch(
      err => {
        localStorage.removeItem("loginAsUser");
        localStorage.removeItem("loginAsUserEmail");
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message,
          });
        }
      }
    );
  };

  checkPageAccess = (permissionName) => {
    const { permissions } = this.state;
    const foundPermission = permissions.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  checkPageAccessCategory = (categoryName) => {
    const { permissions } = this.state;
    const foundPermissions = permissions.filter(elem => elem.category === categoryName);
    return foundPermissions.some(elem => elem.state);
  };

  checkRole = (roleName) => {
    const { roleId } = this.state;
    return roleId === roleName;
  };

  render() {

    return (
      <Row flexGrow={ 1 }>
        <Route exact path="/" component={ EmptyScreen }/>
        <Route exact path="/my-account" component={ MyAccount }/>
        <Route exact path="/docs" component={ API }/>
        { this.checkPageAccessCategory("REFUND") && <Route exact path="/refunds" component={ Refunds }/> }
        { this.checkPageAccessCategory("DASHBOARD") && <Route exact path="/dashboard" component={ APIData }/> }
        { this.checkPageAccessCategory("REPORTS") && <Route exact path="/generate-reports" component={ GenerateReport }/> }
        { this.checkPageAccessCategory("MERCHANT FINANCE SECTION") && <Route exact path="/request-settlement" component={ RequestSettlement } /> }
        { this.checkPageAccessCategory("BANKLIST") && <Route exact path="/bank-codes" component={ BankCodes } /> }
      </Row>
    );
  }
}

export default Module;