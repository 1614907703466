const Dropdowns = {
  periods: [{
    id: 0,
    value: 'today',
    label: 'Today'
  }, {
    id: 1,
    value: 'yesterday',
    label: 'Yesterday'
  }, {
    id: 2,
    value: 'thisMonth',
    label: 'This Month'
  }, {
    id: 3,
    value: 'lastMonth',
    label: 'Last Month'
  }, {
    id: 4,
    value: 'thisYear',
    label: 'This Year'
  }, {
    id: 5,
    value: 'lastYear',
    label: 'Last Year'
  }, {
    id: 6,
    value: 'lastHour',
    label: 'Last Hour'
  }, {
    id: 7,
    value: 'lastSixHours',
    label: 'Last 6 Hours'
  }, {
    id: 8,
    value: 'custom',
    label: 'Custom'
  }],
  companies: [{
    id: 0,
    value: 'one',
    label: 'One'
  },{
    id: 1,
    value: 'two',
    label: 'Two'
  }]
};

export default Dropdowns;