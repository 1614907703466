import React, { Component } from 'react';
import { Panel } from "react-bootstrap";

import Spinner from '../../../Spinner';
import Table from "../../Table";
import '../../../../assets/css/apiData.css';

import { DASHBOARD_DEPOSITS_PER_MIDS } from '../../../../actions/types';
const store =  require('../../../../reducers/index');

class DashBoardDepositsPerMidComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deposits: undefined,
      loading: true,
      socket: false,

      columns: [{
        value: "method",
        label: "Method",
        leftAligned: true,
        isNumber: false
      }, {
        value: "channelId",
        label: "API",
        leftAligned: true,
        isNumber: true
      }, {
        value: "attempts",
        label: "Transactions",
        rightAligned: true,
        isNumber: true
      }, {
        value: "pending",
        label: "Pending",
        rightAligned: true,
        isNumber: true
      }, {
        value: "rejected",
        label: "Rejected",
        rightAligned: true,
        isNumber: true
      }, {
        value: "failed",
        label: "Failed",
        rightAligned: true,
        isNumber: true
      },{
        value: "cancelled",
        label: "Cancelled",
        rightAligned: true,
        isNumber: true
      },{
        value: "reversed",
        label: "Reversed",
        rightAligned: true,
        isNumber: true
      },{
        value: "successful",
        label: "Successful",
        rightAligned: true,
        isNumber: true
      },{
        value: "successRate",
        label: "Success Rate",
        rightAligned: true,
        isNumber: true
      }]
    };
  }

  subscribeFunction = null;

  componentDidMount(){
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      if (state.update === DASHBOARD_DEPOSITS_PER_MIDS && this.props.thisMonth && state.depositsPerMids.socket) {
        this.setState({ deposits: state.depositsPerMids, loading: false, socket: state.depositsPerMids.socket });
        return;
      }

      if (state.update === DASHBOARD_DEPOSITS_PER_MIDS && !state.depositsPerMids.socket) {
        this.setState({ deposits: state.depositsPerMids, loading: false, socket: state.depositsPerMids.socket });
      }
    });
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  tableData = () => {
    const { deposits } = this.state;

    if (!deposits) {
      return [];
    }
    
    return deposits.map(elem => {
      return {
        method: elem.method,
        channelId: elem.channelId,
        attempts: elem.attempts,
        pending: elem.pending,
        failed: elem.failed,
        rejected: elem.rejected,
        successful: elem.successful,
        cancelled: elem.cancelled,
        reversed: elem.reversed,
        successRate: elem.successRate + "%"
      };
    });
  };

  render() {
    const { deposits, loading, columns } = this.state;

    let sellsWrap = (
      <Panel>
        <Panel.Heading>
          <Panel.Title> PAYMENTS PERFORMANCE </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Spinner smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );

    if (!loading && deposits) {
      sellsWrap = (
        <Panel style={ { height: '100%' } }>
          <Panel.Heading>
            <Panel.Title> PAYMENTS PERFORMANCE </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content table-content payout-requests">
              <Table
                columns={ columns }
                headerColor={ "#DFDFDF" }
                data={ this.tableData() }
                isStriped={ false }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return sellsWrap;
  }

}

export default DashBoardDepositsPerMidComponent;