import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel } from "react-bootstrap";

import Spinner from '../../../Spinner';
import Table from '../../Table';

import '../../../../assets/css/apiData.css';

import { DASHBOARD_REVENUE_EVENT } from '../../../../actions/types';
import { toFormattedNumber } from "../../../../utils/utils";
const store =  require('../../../../reducers/index');

class DashBoardRevenueComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      revenue: undefined,
      columns: [{
        value: "currency",
        label: "Currency",
        leftAligned: true,
        isNumber: false
      }, {
        value: "payments",
        label: "Payments",
        isNumber: true,
        rightAligned: true
      }, {
        value: "paymentFees",
        label: "Payment Fees",
        isNumber: true,
        rightAligned: true
      }, {
        value: "payouts",
        label: "Payouts",
        isNumber: true,
        rightAligned: true
      }, {
        value: "payoutFees",
        label: "Payout Fees",
        isNumber: true,
        rightAligned: true
      }, {
        value: "settlements",
        label: "Settlements",
        isNumber: true,
        rightAligned: true
      }],
      loading: true,
      socket: false
    };
  }

  subscribeFunction = null;

  componentDidMount() {
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      let revenue = undefined;
      if (state.revenue) {
        revenue = state.revenue.map(elem => Object.assign(elem, { isOpened: false }));
      }

      if (state.update === DASHBOARD_REVENUE_EVENT && this.props.thisMonth && state.revenue.socket) {
        this.setState({
          revenue: revenue,
          originalRevenue: revenue,
          loading: false,
          socket: state.revenue.socket
        });
        return;
      }

      if (state.update === DASHBOARD_REVENUE_EVENT && !state.revenue.socket) {
        this.setState({
          revenue: revenue,
          originalRevenue: revenue,
          loading: false,
          socket: state.revenue.socket
        });
      }
    });
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  tableData = () => {
    const { revenue } = this.state;

    if (!revenue) {
      return [];
    }

    return revenue.map(elem => {
      const currency = <>
        <span className="currency-symbol"> { elem.currencySymbol }</span>
        { elem.currency }
      </>;
      return {
        currency,
        currencyCode: elem.currency,
        currencySymbol: elem.currencySymbol,
        payments: elem.currencySymbol + " " + toFormattedNumber(elem.payments),
        paymentFees: elem.currencySymbol + " " + toFormattedNumber(elem.paymentFees),
        payouts: elem.currencySymbol + " " + toFormattedNumber(elem.payouts),
        payoutFees: elem.currencySymbol + " " + toFormattedNumber(elem.payoutFees),
        settlements: elem.currencySymbol + " " + toFormattedNumber(elem.settlements)
      };
    });
  };

  render() {
    const { columns, revenue, loading } = this.state;

    let revenuesWrap = <Panel>
      <Panel.Heading>
        <Panel.Title> KEY PERFORMING INDICATORS </Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <Spinner />
      </Panel.Body>
    </Panel>;

    if (!loading && revenue) {
      revenuesWrap = (
        <Panel>
          <Panel.Heading>
            <Panel.Title> KEY PERFORMING INDICATORS </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content table-content revenue-table">
              <Table
                columns={ columns }
                headerColor={ "#DFDFDF" }
                data={ this.tableData() }
                isStriped={ false }
              />
            </div>
            <div className="revenue-table-mobile">
              {this.tableData().map((item, i) =>
                <Column key={ i } horizontal='start' vertical='start' className="single-item" alignSelf='start'>
                  <Row flexGrow={ 1 } wrap={ true } horizontal='center' vertical='center' style={ { width: '100%' } } className="item-header">
                    <span className="currency-symbol"> { item.currencySymbol } </span>
                    { item.currencyCode }
                  </Row>
                  <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="item-body">
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } alignItems='end'>
                          { item.payments }
                          <p>Payments</p>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } alignItems='end'>
                          { item.payouts }
                          <p>Payouts</p>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } alignItems='end'>
                          { item.settlements }
                          <p>Settlements</p>
                        </Column>
                      </Row>
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="item-body">
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } alignItems='end'>
                          { item.paymentFees }
                          <p>Payment Fees</p>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } alignItems='end'>
                          { item.payoutFees }
                          <p>Payout Fees</p>
                        </Column>
                      </Row>
                    </Column>
                  </Row>
                </Column>
              )}
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return revenuesWrap;
  }

}

export default DashBoardRevenueComponent;