import React, {Component} from 'react';

import '../../../../assets/css/apiData.css';
import ComponentHeader from "../../../componentHeader";
import DashboardIcon from '../../../../assets/images/dashboardIcon.png';
import DashboardIconTrustbucks from '../../../../assets/images/trustbucks/dashboardIcon.png';
import DashboardIconPacificCapital from '../../../../assets/images/pacificCapital/dashboardIcon.png';
import DashboardIconT365 from '../../../../assets/images/t365/dashboardIcon.png';
import { whiteLabel } from '../../../../config';

class LastUpdateComponent extends Component {
  state = {
    pageTitle: 'Dashboard'
  };

  getIcon = () => {
    switch (whiteLabel) {
    case "t365":
      return DashboardIconT365;
    case "trustbucks":
      return DashboardIconTrustbucks;
    case "pacificCapital":
      return DashboardIconPacificCapital;
    default:
      return DashboardIcon;
    }
  };

  render() {
    const { pageTitle } = this.state;
    const { lastUpdate } = this.props;

    return (
      <ComponentHeader
        title={ pageTitle }
        lastUpdate={ lastUpdate }
        img={ this.getIcon() }
      />
    );
  }
}

export default LastUpdateComponent;