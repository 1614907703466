module.exports = {
  UNIONPAY: 'UnionPay Transfer',
  BANKPAY: 'Fast Transfer',
  ALIPAY: 'Alipay',
  UNIONPAY_INTERNATIONAL: 'UnionPay Card',
  UNIONPAY_INTERNATIONAL_EURO: 'UnionPay Card EURO',
  QUICKPAY: 'QuickPay',
  BANK_TRANSFER: 'Bank Transfer',
  CREDIT_CARD: "Credit Card",
  COD: "Cash On Delivery",
  UPI: "UPI",
  NETBANKING: "Netbanking",
  DEBIT_CARD: "Debit Card",
  MASTERCARD: "MasterCard",
  VISA: "Visa",
  JCB: "JCB",
  AMERICAN_EXPRESS: "American Express",
  DISCOVER: "Discover",
  DINERS_CLUB: "Diners Club",
  UPI_PHONE_PE: "UPI - PhonePE",
  UPI_PAY_TM: "UPI - PayTM",
  UPI_BHIM: "UPI - BHIM",
  G_PAY: "G Pay",
  CUP: "CUP Wallet",
  EXPRESS_BANK_TRANSFER: "Express Bank Transfer",
  VIRTUAL_ACCOUNT_IDR: "Virtual Account IDR",
  QR_CODE_THB: "QR Code THB"
};