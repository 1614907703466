import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './components/App';
import store from'./reducers/index';

import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';

import common_cn from "./assets/i18n/cn.js";
import common_en from "./assets/i18n/en.js";

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: !localStorage.language ? 'en' : localStorage.language,
  resources: {
    en: {
      common: common_en  // 'common' is custom namespace
    },
    cn: {
      common: common_cn
    },
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={ i18next }>
    <Provider store={ store }>
      <App />
    </Provider>
  </I18nextProvider>
);