import authReducer from './authReducer';
import dashboardReducer from './dashboardReducer';
import messageReducer from './messageReducer';

import { combineReducers, createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';

const reducer = combineReducers({ authReducer, dashboardReducer, message: messageReducer});
const createStoreWithMiddleware = applyMiddleware(
  thunk // lets us dispatch() functions
)(createStore);

const store = createStoreWithMiddleware(reducer);

export default store;