import React, { Component } from 'react';

import ComponentHeader from "../../componentHeader";
import { Row, Column } from 'simple-flexbox';
import { Panel } from "react-bootstrap";
import APIIcon from '../../../assets/images/api.png';
import APIIconTrustbucks from '../../../assets/images/trustbucks/api.png';
import APIIconT365 from '../../../assets/images/t365/api.png';
import APIIconPacificCapital from '../../../assets/images/pacificCapital/api.png';

import '../../../assets/css/pacificCapital/sandbox.scss';
import '../../../assets/css/t365/sandbox.scss';
import '../../../assets/css/trustbucks/sandbox.scss';
import '../../../assets/css/sandbox.css';
import { whiteLabel, apiUrl } from '../../../config';

class API extends Component {

  getIcon = () => {
    switch (whiteLabel) {
    case "t365":
      return APIIconT365;
    case "pacificCapital":
      return APIIconPacificCapital;
    case "trustbucks":
      return APIIconTrustbucks;
    default:
      return APIIcon;
    }
  };

  render() {
    return (
      <Row flexGrow={ 1 } className={ `${whiteLabel} module sandbox` } vertical='start'>
        <Column flexGrow={ 1 }>
          <ComponentHeader
            title={ 'Application Programming Interface' }
            img={ this.getIcon() }/>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    API DOCUMENTATION
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>
                    <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column" style={ { width: "75%" } }>
                        <h4> Click to view the API documentation </h4>
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf="center" className="input-column">
                        <a
                          type="submit"
                          className="btn defaultBtn"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={ apiUrl }
                        >
                          VIEW
                        </a>
                      </Column>
                    </Row>
                  </div>
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>
      </Row>
    );
  }
}

export default API;
