import React from 'react';
import SweetAlert2 from 'react-sweetalert2';

const Alert = ( props ) => {
  const { show, text, title, type, confirmButtonColor, onConfirm } = props;

  return (
    <SweetAlert2
      show={ show }
      title={ title }
      icon={ type }
      text={ text }
      confirmButtonColor={ confirmButtonColor }
      onConfirm={ onConfirm }
      onResolve={ onConfirm }
    />
  );
};

export default Alert;
