import React, { Component } from 'react';
import { Panel } from "react-bootstrap";

import Spinner from '../../../Spinner';
import Table from "../../Table";

import '../../../../assets/css/apiData.css';

import { toFormattedNumber } from "../../../../utils/utils.js";
import paymentMethods from "../../softwareIntegration/payment-methods";
import { DASHBOARD_PAYOUTS_PROCESSING_FEE } from '../../../../actions/types';
const store =  require('../../../../reducers/index');

class DashBoardPayoutsProcessingFee extends Component {

  constructor(props) {
    super(props);

    this.state = {
      payoutsFees: undefined,
      loading: true,
      socket: false,
      columns: [{
        value: "method",
        label: "Payout Method"
      }, {
        value: "currency",
        label: "Currency"
      }, {
        value: "deposits",
        label: "Payouts",
        rightAligned: true,
        isNumber: true
      }, {
        value: "rate",
        label: "Rate",
        rightAligned: true,
        isNumber: true
      }, {
        value: "processingFees",
        label: "Processing Fees",
        rightAligned: true,
        isNumber: true
      }]
    };
  }

  subscribeFunction = null;

  componentDidMount(){
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      if (state.update === DASHBOARD_PAYOUTS_PROCESSING_FEE && this.props.thisMonth && state.payoutFees.socket) {
        this.setState({ payoutsFees: state.payoutFees, loading: false, socket: state.payoutFees.socket });
        return;
      }

      if (state.update === DASHBOARD_PAYOUTS_PROCESSING_FEE && !state.payoutFees.socket) {
        this.setState({ payoutsFees: state.payoutFees, loading: false, socket: state.payoutFees.socket });
      }
    });
  }

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  tableData = () => {
    const { payoutsFees } = this.state;

    if (!payoutsFees) {
      return [];
    }

    return payoutsFees.map(elem => {
      return {
        method: paymentMethods[elem.method],
        currency: elem.currency,
        deposits: toFormattedNumber(elem.deposits),
        rate: elem.rate + "%",
        processingFees: toFormattedNumber(elem.processingFees)
      };
    });
  };

  render() {
    const { payoutsFees, loading, columns } = this.state;

    let payoutWrap = (
      <Panel>
        <Panel.Heading>
          <Panel.Title> PAYOUT PROCESSING FEES </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Spinner smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );

    if (!loading && payoutsFees) {
      payoutWrap = (
        <Panel style={ { height: '100%' } }>
          <Panel.Heading>
            <Panel.Title> PAYOUT PROCESSING FEES </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content table-content">
              <Table
                columns={ columns }
                headerColor={ "#DFDFDF" }
                data={ this.tableData() }
                isStriped={ false }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return payoutWrap;
  }

}

export default DashBoardPayoutsProcessingFee;