import { crudService } from "./crudService";

export const crudActions = {
  get,
  post,
  put
};

function get(endpoint) {
  return crudService
    .get(endpoint)
    .catch(err => {
      return Promise.reject(err);
    }).then(handleSuccess, handleError);
}

function post(endpoint, jsonConfig, customHeaders = {}) {
  return crudService
    .post(endpoint, jsonConfig, customHeaders)
    .catch(err => {
      return Promise.reject(err);
    }).then(handleSuccess, handleError);
}

function put(endpoint, jsonConfig, customHeaders = {}) {
  return crudService
    .put(endpoint, jsonConfig, customHeaders)
    .catch(err => {
      return Promise.reject(err);
    })
    .then(handleSuccess, handleError);
}

function handleSuccess(resp) {
  return resp;
}

function handleError(err) {
  return err.json().then(json => {
    return Promise.reject(json);
  }).catch(
    error => {
      if (error.hasOwnProperty("message")) {
        return Promise.reject(error);
      } else {
        return Promise.reject({
          message: error
        });
      }
    }
  );
}