import React from 'react';
import '../assets/css/spinner.css';
import { whiteLabel } from '../config';

const spinner = (props) => (
  <div
    className={ props.smallContainer ? 'Loader small' : 'Loader' }
    style={ {color: props.color || (whiteLabel === 'trustbucks' && '#d29d02') || (whiteLabel === 't365' && '#f19c49') || (whiteLabel === 'pacificCapital' && '#2b637c')} }
  >
    Loading...
  </div>
);

export default spinner;