import { FETCH_USER, SHOW_MESSAGE } from './types';

export const fetchTab = (tabId) => dispatch => {
  dispatch({ type: FETCH_USER, payload: tabId });
};

export const showMessage = message => dispatch => {
  const data = {
    message,
    show: true
  };

  dispatch({ type: SHOW_MESSAGE, payload: data });

  setTimeout(() => {
    dispatch({ type: SHOW_MESSAGE, payload: data.show = false });
  }, 5000);
};
