import Errors from "./Errors";
import Validators from "./Validators";

export default class Form {
  /**
   * Create a new Form instance.
   *
   * @param {object} data
   */
  constructor(data, fieldRules) {
    this.originalData = data;
    this.fieldRules = fieldRules || [];

    for (const field in data) {
      this[field] = data[field];
    }
    this.errors = new Errors();
  }

  isFormValid() {
    const fieldRules = this.fieldRules;
    let i = fieldRules.length;

    this.errors.clear();
    while (i--) {
      const field = fieldRules[i];
      const fieldName = field.name;
      if (!this.hasOwnProperty(fieldName)) {
        continue;
      }
      const fieldType = field.type;
      if (typeof Validators[fieldType] !== "function") {
        continue;
      }
      const errorMessage = Validators[fieldType](this[fieldName], field.rules);
      if (errorMessage !== null) {
        this.errors.addRecord(fieldName, errorMessage);
      }
    }
    return !this.errors.any();
  }

  isFormFieldValid(fieldName) {
    const fieldRules = this.fieldRules;
    let i = fieldRules.length;

    // Create error coppy to not disable vue reactivity
    const errorsCopy = Object.assign({}, this.errors.getErrors());
    this.errors.record(errorsCopy);
    while (i--) {
      const field = fieldRules[i];
      if (fieldName === field.name) {
        this.errors.clear(fieldName);
        if (!this.hasOwnProperty(fieldName)) {
          return false;
        }
        const fieldType = field.type;
        if (typeof Validators[fieldType] !== "function") {
          return false;
        }
        const errorMessage = Validators[fieldType](this[fieldName], field.rules);
        if (errorMessage !== null) {
          //this.errors.clear();
          this.errors.addRecord(fieldName, errorMessage);
          return false;
        }
        return true;
      }
    }
    return false;
  }

  /**
   * Fetch all relevant data for the form.
   */
  data() {
    const data = {};

    for (const property in this.originalData) {
      data[property] = this[property];
    }
    return data;
  }

  /**
   * Reset the form fields.
   */
  reset() {
    for (const field in this.originalData) {
      if (this[field]) {
        this[field] = typeof this[field] === "number" ? 0 : "";
      }

    }

    this.errors.clear();
  }

  /**
   * Handle a successful form submission.
   *
   * @param {object} data
   */
  onSuccess(/*data*/) {
    this.reset();
  }

  /**
   * Handle a failed form submission.
   *
   * @param {object} errors
   */
  onFail(errors) {
    this.errors.record(errors);
  }
}