module.exports = {
  // api key
  apikey: 'REACT_APP_APIKEY',
  backEndBaseUrl: 'https://REACT_APP_BACKENDBASEURL',
  frontEndUrl: 'https://REACT_APP_FRONTENDURL',
  captchaSiteKey: "REACT_APP_CAPTCA",
  merchant: "REACT_APP_MERCHANT",
  whiteLabelId: "REACT_APP_WL_ID",
  whiteLabel: "REACT_APP_WHITELABEL",
  apiUrl: 'https://REACT_APP_API_URL'
};
